import { ProductCategoriesDTO } from '@chillmenu/common/dist/features/products/categories/productCategories.dto';
import { generateUseContext } from '@chillmenu/common/dist/lib/generateUseContext';
import React, { createContext } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ShopViewerDrawerContextI {
  categories: ProductCategoriesDTO[];
  setProductCategories: (categories: ProductCategoriesDTO[]) => void;
  title: string | JSX.Element;
  setTitle: (title: string | JSX.Element) => void;
}

export const ShopViewerDrawerContext = createContext<
  ShopViewerDrawerContextI | undefined
>(undefined);

export const useShopViewerDrawerContext = generateUseContext(
  ShopViewerDrawerContext,
);

export const ShopViewerDrawerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [categories, setProductCategories] = React.useState<
    ShopViewerDrawerContextI['categories']
  >([]);

  const [title, setTitle] = React.useState<ShopViewerDrawerContextI['title']>(
    <FormattedMessage id="ShopViewerDrawer.Categories" />,
  );

  return (
    <ShopViewerDrawerContext.Provider
      value={{
        categories,
        setProductCategories,
        title,
        setTitle,
      }}
    >
      {children}
    </ShopViewerDrawerContext.Provider>
  );
};
