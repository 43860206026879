import { useLocation, useNavigate } from 'react-router-dom';
import { wrongIdentifierSearch } from '../features/shops/ShopViewForm/ShopViewForm.page';

type NavigateType = ReturnType<typeof useNavigate>;
type LocationType = ReturnType<typeof useLocation>;

export class Navigate {
  constructor(
    private navigate: NavigateType,
    private location: LocationType,
    private mode: 'push' | 'replace' = 'push',
  ) {}

  private goTo(url: string) {
    const { hash } = this.location;
    if (this.mode === 'push') {
      this.navigate(url + hash);
    } else if (this.mode === 'replace') {
      this.navigate(url + hash, { replace: true });
    }
  }

  public shopMenuViewer(shopIdentifier: string): void {
    this.goTo(`/${shopIdentifier}`);
  }

  public wrongSearchIdentifier(): void {
    this.goTo(wrongIdentifierSearch.url());
  }
}
