import { useCallback } from 'react';
import { LocaleCode } from './localeCodes.const';
import { LocaleObject } from './localeObject.type';
import { localeObjectToString } from './localeObjectToString';

export const useLocaleObjectToString = (locale: LocaleCode) => {
  const callback = useCallback(
    (localeObject: LocaleObject) => localeObjectToString(localeObject, locale),
    [locale],
  );

  return callback;
};
