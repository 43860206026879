import React from 'react';
import { Link } from '@mui/material';

export const MailTo = ({
  email,
  subject = '',
  body = '',
  children,
}: {
  email: string;
  subject?: string;
  body?: string;
  children: React.ReactNode;
}): JSX.Element => {
  const paramsParts: string[] = [];
  if (subject) {
    paramsParts.push(`subject=${encodeURIComponent(subject)}`);
  }
  if (body) {
    paramsParts.push(`body=${encodeURIComponent(body)}`);
  }

  const params = paramsParts.length ? `?${paramsParts.join('&')}` : '';

  return (
    <Link
      href={`mailto:${email}${params}`}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
        e.stopPropagation()
      }
      underline="hover"
    >
      {children}
    </Link>
  );
};
