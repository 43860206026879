import React from 'react';
import { LocaleSelector } from '../internationalization/localeSelector/LocaleSelector';
import { ShopViewerDrawer } from '../shops/ShopViewerDrawer/ShopViewerDrawer';
import { ViewMenu } from './ViewMenu/ViewMenu';

export const MenuViewer = (): JSX.Element => {
  return (
    <ShopViewerDrawer title="Menu" label="shop viewer drawer">
      <LocaleSelector />
      <ViewMenu />
    </ShopViewerDrawer>
  );
};
