import React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '../../chillmenu-components/Theme';

const drawerWidth = 240;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },

  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    willChange: 'transform',
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar as any,

  drawerPaper: {
    width: drawerWidth,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

interface Props {
  drawerLists: JSX.Element;
  label: string;
  /** will be used for the toolbar content */
  title: string | JSX.Element;
  children: React.ReactNode;
  /** style for the drawer button */
  toolbarIconStyle?: React.CSSProperties;
  /** if set to true, a click on any part of the toolbar will open the drawer */
  toolbarTogglesDrawer?: boolean;
  /** gets a function which closes the Drawer in the mobile view */
  getCloseMobile?: (fn: () => void) => void;
  /** gets a function which opens the Drawer in the mobile view */
  getOpenMobile?: (fn: () => void) => void;
}

export default function ResponsiveDrawer(props: Props): JSX.Element {
  const theme = useTheme();
  const { classes } = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  props.getCloseMobile?.(() => setMobileOpen(false));
  props.getOpenMobile?.(() => setMobileOpen(true));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      {props.drawerLists}
    </div>
  );

  const toolbarElement = (
    <Toolbar
      style={{
        cursor: props.toolbarTogglesDrawer ? 'pointer' : 'default',
      }}
      onClick={() => {
        if (props.toolbarTogglesDrawer) {
          handleDrawerToggle();
        }
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => {
          if (!props.toolbarTogglesDrawer) {
            handleDrawerToggle();
          }
        }}
        className={classes.menuButton}
        style={props.toolbarIconStyle || {}}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap>
        {props.title}
      </Typography>
    </Toolbar>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="primary">
        {toolbarElement}
      </AppBar>
      <nav className={classes.drawer} aria-label={props.label}>
        <Drawer
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
          }}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            disableScrollLock: true,
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          ModalProps={{
            disableScrollLock: true,
          }}
          open
        >
          {drawer}
        </Drawer>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
