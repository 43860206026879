import { LocaleCode, localeCodes } from './localeCodes.const';
import { LocaleObject } from './localeObject.type';

/** with default values of other locales */
export const localeObjectToString = (
  localeObject: LocaleObject,
  locale: LocaleCode,
): string => {
  const localesPriority = new Set<LocaleCode>([
    locale,
    'en-US',
    'el-GR',
    ...localeCodes,
  ]);

  for (const localeToUse of Array.from(localesPriority)) {
    const value = localeObject[localeToUse];
    if (value) {
      return value;
    }
  }

  return '';
};
