export const localeCodes = [
  'el-GR',
  'en-US',
  'it-IT',
  'es-ES',
  'de-DE',
  'fr-FR',
  'zh-CN',
] as const;

export type LocaleCode = typeof localeCodes[number];
