import React from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';
import flags from 'country-flag-icons/react/3x2';
import { makeStyles } from '../../chillmenu-components/Theme';
import {
  LocaleCode,
  localeTranslationsInGreek,
  localeTranslationsInOwnLocale,
} from '../../src/internationalization';
import { hasFlag } from 'country-flag-icons';
import { localeFlagCodes } from '../../src/internationalization/localeFlagCodes.const';

const useStyles = makeStyles<{ size: 'small' | 'medium' }>()((_, { size }) => ({
  Icon: {
    width: size === 'small' ? '2rem' : '2.5rem',
    height: size === 'small' ? '1rem' : '1.5rem',
  },
}));

type FlagCodes = {
  [key in LocaleCode]: string;
};

export const FlagDropdown = ({
  locales,
  value,
  setValue,
  label,
  iconOnly,
  size = 'medium',
  localeTitles,
}: {
  locales: LocaleCode[];
  value: LocaleCode;
  setValue: (l: LocaleCode) => void;
  label?: string;
  iconOnly?: boolean;
  size?: 'small' | 'medium';
  localeTitles?: 'greek' | 'own language';
}) => {
  const { classes } = useStyles({ size });
  const id = React.useRef(Math.random().toString());

  const flagCodes = React.useMemo((): FlagCodes => {
    return locales.reduce((_flagCodes, locale) => {
      const flagCode = localeFlagCodes[locale];

      if (hasFlag(flagCode)) {
        _flagCodes[locale] = flagCode;
      }

      return _flagCodes;
    }, {} as FlagCodes);
  }, [locales]);

  return (
    <>
      {label && <InputLabel id={id.current}>{label}</InputLabel>}
      <Select
        labelId={id.current}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value as LocaleCode)}
        MenuProps={{
          disableScrollLock: true,
        }}
        variant="standard"
        disableUnderline={iconOnly}
        IconComponent={iconOnly ? () => null : undefined}
        inputProps={iconOnly ? { sx: { padding: '0 !important' } } : undefined}
      >
        {Object.entries(flagCodes).map(([locale, flagCode]) => (
          <MenuItem key={locale} value={locale}>
            {flags[flagCode]({
              title:
                localeTitles === 'greek'
                  ? localeTranslationsInGreek[locale as LocaleCode]
                  : localeTranslationsInOwnLocale[locale as LocaleCode],
              className: classes.Icon,
            })}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
