/** formats currency for visual purposes */
export const normalizedToFormatted = (
  cents: number,
  currencySymbol: string,
  /** an optional label to prepend */
  label = '',
): string => {
  if (typeof cents !== 'number' || isNaN(cents)) {
    return `${label} -`.trim();
  }
  const priceWithSymbol = (price: string) => {
    return `${price} ${currencySymbol}`;
  };

  const formattedAmount = priceWithSymbol((cents / 100).toFixed(2));

  return `${label} ${formattedAmount}`.trim();
};

export const floatToNormalized = (float: number): number => {
  return Math.round(float * 100);
};

export const normalizedToFloat = (normalized: number): number => {
  return +(normalized / 100).toFixed(2);
};
