import { Box } from '@mui/material';
import React from 'react';

type CloudFlareVariants =
  | 'public'
  | 'StoreFrontProductDetails'
  | 'StoreFrontProductList'
  | 'DashboardProductList';

export const Image: React.FC<{
  url: string;
  alt: string;
  /** CloudFlare images variant */
  variant: CloudFlareVariants;
  /** CloudFlare images variant to show until loading the full-size variant is complete */
  beforeLoadVariant?: CloudFlareVariants;
  style?: React.CSSProperties;
}> = (props) => {
  const { url, alt, variant, beforeLoadVariant } = props;
  const [currentVariant, setCurrentVariant] = React.useState(
    beforeLoadVariant || variant,
  );
  const style: React.CSSProperties = {
    objectFit: 'cover',
    maxWidth: '100%',
    borderRadius: '10px',
    ...props.style,
  };

  React.useLayoutEffect(() => {
    if (!beforeLoadVariant || !url) {
      return () => {
        /** */
      };
    }

    // let's load the final image here before showing it
    const finalImg = new global.Image();
    finalImg.onload = () => {
      setCurrentVariant(variant);
    };
    finalImg.src = new URL(variant, url).toString();

    return () => (finalImg.onload = null);
  }, [url, variant, beforeLoadVariant]);

  if (!url) {
    return <></>;
  }

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <img
        src={new URL(currentVariant, url).toString()}
        width="100%"
        style={style}
        alt={alt}
      />
    </Box>
  );
};
