import React from 'react';

export const Conditional = ({
  condition,
  children,
}: {
  condition: boolean;
  children: React.ReactNode;
}): JSX.Element | null => {
  if (!condition || !children) {
    return null;
  }

  return <>{children}</>;
};
