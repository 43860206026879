import React from 'react';
import { styled } from '@mui/material/styles';
import { ShopViewForm } from '../shops/ShopViewForm/ShopViewForm.page';
import { DashboardLinker } from '../dashboard/DashboardLinker';
const PREFIX = 'Home';

const classes = {
  container: `${PREFIX}-container`,
};

const Root = styled('div')({
  [`&.${classes.container}`]: {
    padding: '5px',
  },
});

export const Home = (): JSX.Element => {
  return (
    <Root className={classes.container}>
      <ShopViewForm />
      <DashboardLinker />
    </Root>
  );
};
