import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { normalizedToFormatted } from '@chillmenu/common/dist/lib/currencyFormatter';
import { ProductDTO } from '@chillmenu/common/features/products/product.dto';
import { useLocaleObjectToString } from '../../../intl/useLocaleObjectToString.hook';

const PREFIX = 'ProductPrice';

const classes = {
  PriceTypography: `${PREFIX}-PriceTypography`,
  PriceContainer: `${PREFIX}-PriceContainer`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.PriceTypography}`]: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    color: theme.palette.text.primary,
  },

  [`& .${classes.PriceContainer}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
  },
}));

export const ProductPrice = (props: {
  showUnavailability: boolean;
  product: Pick<ProductDTO, 'isAvailable' | 'pricing'>;
}): JSX.Element => {
  const intl = useLocaleObjectToString();
  const currencySymbol = '€';

  const { product, showUnavailability } = props;

  // show "not available" if price is unavailable, else show price
  if (showUnavailability && !product.isAvailable) {
    return <FormattedMessage id="ProductPrice.ProductNotAvailable" />;
  }

  return (
    <>
      {props.product.pricing
        .sort((a, b) => (a.amount > b.amount ? 1 : -1))
        .map((pricing, index) => (
          <StyledTypography
            className={classes.PriceTypography}
            variant="body1"
            key={index}
          >
            {normalizedToFormatted(
              pricing.amount,
              currencySymbol,
              intl(pricing.name),
            )}
          </StyledTypography>
        ))}
    </>
  );
};
