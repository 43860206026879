import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, alpha } from '@mui/material';
import { ProductDTO } from '../../features/products/product.dto';
import {
  LocaleCode,
  useLocaleObjectToString,
} from '../../src/internationalization';

const PREFIX = 'ProductDescription';

const classes = {
  ProductDescriptionTypography: `${PREFIX}-ProductDescriptionTypography`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.ProductDescriptionTypography}`]: {
    color: alpha(theme.palette.text.primary, 0.7),
  },
}));

export const ProductDescription = (props: {
  product: Pick<ProductDTO, 'description'>;
  locale: LocaleCode;
}): JSX.Element => {
  const { product, locale } = props;

  const intl = useLocaleObjectToString(locale);

  return (
    <StyledTypography
      className={classes.ProductDescriptionTypography}
      variant="body2"
    >
      {intl(product.description)}
    </StyledTypography>
  );
};
