import { TimeOrClosed } from '@chillmenu/common/dist/features/shops/shops.dto';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LocaleIds } from '../../../intl/intl';
import { makeStyles } from '@chillmenu/common/dist/chillmenu-components/Theme';

const useStyles = makeStyles()((theme) => ({
  Bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type WeekDay =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

const dayIndexes: Record<WeekDay, number> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const weekDayToLocaleId: Record<WeekDay, LocaleIds> = {
  Monday: 'Days.Monday',
  Tuesday: 'Days.Tuesday',
  Wednesday: 'Days.Wednesday',
  Thursday: 'Days.Thursday',
  Friday: 'Days.Friday',
  Saturday: 'Days.Saturday',
  Sunday: 'Days.Sunday',
};

const DayDataTypography = (props: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Typography variant="body1" className={props.className} sx={props.style}>
      {props.children}
    </Typography>
  );
};

export const DaySchedule = (props: {
  day: WeekDay;
  start: TimeOrClosed;
  end: TimeOrClosed;
}): JSX.Element => {
  const { day, start, end } = props;

  const { classes } = useStyles();

  const isToday = React.useMemo(() => new Date().getDay() === dayIndexes[day], [
    day,
  ]);

  const dayComponent = <FormattedMessage id={weekDayToLocaleId[day]} />;

  const className = isToday ? classes.Bold : '';
  if (start === 'closed' || end === 'closed') {
    return (
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DayDataTypography
          style={{ marginRight: '10px' }}
          className={className}
        >
          {dayComponent}{' '}
        </DayDataTypography>
        <DayDataTypography className={className}>
          <FormattedMessage id="ShopInfo.ScheduleClosed" />
        </DayDataTypography>
      </Box>
    );
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DayDataTypography style={{ marginRight: '10px' }}>
          {dayComponent}{' '}
        </DayDataTypography>
        <DayDataTypography className={className}>
          {start} - {end}
        </DayDataTypography>
      </div>
    );
  }
};
