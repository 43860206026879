import { makeStyles } from '@chillmenu/common/dist/chillmenu-components/Theme';
import React, { useCallback } from 'react';
import { FlagDropdown } from '@chillmenu/common/dist/components/FlagDropdown/FlagDropdown';
import { Box } from '@mui/material';
import { useIntlContext } from '../../../intl/intl.context';
import { useShopViewerContext } from '../../shops/shopViewer.context';
import { LocaleCode } from '@chillmenu/common/dist/src/internationalization';
import { setUserPreferredLocale } from '../../../intl/intl';

const useStyles = makeStyles()((theme) => ({
  Container: {
    position: 'fixed',
    right: '10px',
    zIndex: theme.zIndex.drawer + 1,
    // accounting the drawer's height
    top: '20px',
    [theme.breakpoints.down('sm')]: {
      top: '16px',
    },
  },
}));

export const LocaleSelector: React.FC = () => {
  const { classes } = useStyles();

  const { locale, setLocale } = useIntlContext();
  const { shop } = useShopViewerContext();

  const onSetLocale = useCallback(
    (locale: LocaleCode) => {
      setLocale(locale);
      setUserPreferredLocale(locale);
    },
    [setLocale],
  );

  if (!shop || shop.preferredLocales.length <= 1) {
    return null;
  }

  return (
    <Box className={classes.Container}>
      <FlagDropdown
        locales={shop.preferredLocales}
        value={locale}
        setValue={onSetLocale}
        iconOnly
      />
    </Box>
  );
};
