import { Scroller } from '@chillmenu/common/dist/chillmenu-components/Scroller';
import { MenuProducts } from '@chillmenu/common/dist/chillmenu-components/MenuProducts';
import React from 'react';
import { ShopInfoListEntry } from '../../shops/ShopInfoListEntry/ShopInfoListEntry';
import { useShopViewerContext } from '../../shops/shopViewer.context';
import { useLocation } from 'react-router';
import { CategoryHeader } from '@chillmenu/common/dist/chillmenu-components/CategoryHeader';
import { useSortedProducts } from '@chillmenu/common/dist/features/products/useSortedProducts.hook';
import { Grid } from '@mui/material';
import { ViewProductCard } from '../../products/ProductCard/ViewProductCard';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useShopViewerDrawerContext } from '../../shops/ShopViewerDrawer/shopViewerDrawer.context';

const scroller = new Scroller();

export const ViewMenu = (): JSX.Element => {
  const { locale } = useIntl();

  const { menu, categories, products: rawProducts } = useShopViewerContext();

  const {
    setProductCategories: setDrawerProductCategories,
  } = useShopViewerDrawerContext();

  const sortedProducts = useSortedProducts({
    products: rawProducts,
    sortByAvailability: true,
  });

  const showProductIndexes = menu?.showProductIndexes ?? false;

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    setDrawerProductCategories?.(categories);
  }, [categories, setDrawerProductCategories]);

  React.useEffect(() => {
    if (!menu) {
      return;
    }
    scroller.scrollOnceHistoryHash({ navigate, location }, true);
  }, [menu, location, navigate]);

  if (!menu) {
    return <></>;
  }

  return (
    <>
      <ShopInfoListEntry />

      <MenuProducts
        categories={categories}
        products={sortedProducts}
        categoryComponent={({ category, categoryId, products }) => (
          <>
            <Scroller.Component type="category" categoryId={categoryId} />
            <CategoryHeader category={category} locale={locale} />
            <Grid container spacing={1}>
              {products.map((product) => (
                <Grid item lg={6} xs={12} key={product.id}>
                  <ViewProductCard
                    product={product}
                    showProductIndexes={showProductIndexes}
                    key={product.id}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      />
    </>
  );
};
