import React from 'react';
import { styled } from '@mui/material/styles';
import { ProductCategoriesDTO } from '../../features/products/categories/productCategories.dto';
import { ProductDTO } from '../../features/products/product.dto';
import { useCategoriesWithProductsMap } from '../../features/products/categories/useCategoriesWithProductsMap.hook';

const PREFIX = 'MenuProducts';

const classes = {
  Container: `${PREFIX}-Container`,
  CategoryWithProductsContainer: `${PREFIX}-CategoryWithProductsContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.Container}`]: {
    width: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  [`& .${classes.CategoryWithProductsContainer}`]: {
    marginBottom: theme.spacing(2),
  },
}));

export const MenuProducts = (props: {
  categories: ProductCategoriesDTO[];
  products: ProductDTO[];
  categoryComponent: (params: {
    categoryId: string;
    products: ProductDTO[];
    category: ProductCategoriesDTO;
  }) => JSX.Element;
}): JSX.Element => {
  const { categories, products, categoryComponent } = props;

  const categoriesWithProductsMap = useCategoriesWithProductsMap({
    categories,
    products,
  });

  return (
    <Root className={classes.Container}>
      {Object.entries(categoriesWithProductsMap).map(
        ([categoryId, categoryData]) => (
          <div
            className={classes.CategoryWithProductsContainer}
            key={categoryId}
          >
            {categoryComponent({
              categoryId,
              category: categoryData.category,
              products: categoryData.products,
            })}
          </div>
        ),
      )}
    </Root>
  );
};
