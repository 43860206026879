import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ProductCategoriesDTO } from '../../features/products/categories/productCategories.dto';
import { Conditional } from '../../components/Utils/Conditional';
import { Divider } from '../../components/Divider/Divider';
import {
  useLocaleObjectToString,
  LocaleCode,
} from '../../src/internationalization';

const PREFIX = 'CategoryHeader';

const classes = {
  CategoryNameTypography: `${PREFIX}-CategoryNameTypography`,
  CategoryDescriptionTypography: `${PREFIX}-CategoryDescriptionTypography`,
  BelowHeaderComponentContainer: `${PREFIX}-BelowHeaderComponentContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.CategoryNameTypography}`]: {
    textAlign: 'left',
    paddingLeft: '2%',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },

  [`& .${classes.CategoryDescriptionTypography}`]: {
    textAlign: 'left',
    paddingLeft: '2%',
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.primary,
  },

  [`& .${classes.BelowHeaderComponentContainer}`]: {
    textAlign: 'left',
    paddingLeft: '1%',
  },
}));

export const CategoryHeader: React.FC<{
  category: ProductCategoriesDTO;
  locale: LocaleCode;
  /** a component to show below the description and above the divider */
  belowHeaderComponent?: JSX.Element;
}> = (props) => {
  const { belowHeaderComponent, category, locale } = props;

  const intl = useLocaleObjectToString(locale);

  return (
    <Root>
      <Typography className={classes.CategoryNameTypography} variant="h5">
        {intl(category.name)}
      </Typography>
      {category.description && (
        <Typography
          className={classes.CategoryDescriptionTypography}
          variant="body2"
        >
          {intl(category.description)}
        </Typography>
      )}
      <Conditional condition={!!belowHeaderComponent}>
        <div className={classes.BelowHeaderComponentContainer}>
          {belowHeaderComponent}
        </div>
      </Conditional>
      <Divider heavy />
    </Root>
  );
};
