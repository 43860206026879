import { request, requestUrls } from '../../utilities/requests';
import { ShopsReadDTO } from '@chillmenu/common/dist/features/shops/shops.storefront.dto';
import {
  ProductsReadManyDTO,
  ProductsReadOneDTO,
} from '@chillmenu/common/dist/features/products/products.storefront.dto';
import { ProductCategoriesReadManyDTO } from '@chillmenu/common/dist/features/products/categories/productCategories.storefront.dto';
import { MenusReadDTO } from '@chillmenu/common/dist/features/menus/menus.storefront.dto';
import { LocaleCode } from '@chillmenu/common/src/internationalization';

export const getShop = async ({
  locale,
  shop,
}: {
  locale: LocaleCode;
  shop: string;
}): Promise<ShopsReadDTO['Response']> => {
  const search: Required<
    Pick<ShopsReadDTO['Request']['query'], 'locale' | 'shop'>
  > = {
    locale,
    shop,
  };

  const searchParams = new URLSearchParams({
    ...search,
  }).toString();

  const res = await request<ShopsReadDTO['Response']>({
    method: 'GET',
    url: requestUrls.shops.base() + '?' + searchParams,
  });

  return res;
};

export const getMenu = async ({
  shopId,
}: {
  shopId: string;
}): Promise<MenusReadDTO['Response']> => {
  const res = await request<MenusReadDTO['Response']>({
    method: 'GET',
    url: requestUrls.menus.base(shopId),
  });

  return res;
};

export const getProducts = async ({
  shopId,
}: {
  shopId: string;
}): Promise<ProductsReadManyDTO['Response']> => {
  const res = await request<ProductsReadManyDTO['Response']>({
    method: 'GET',
    url: requestUrls.products.base(shopId),
  });

  return res;
};

export const getProductCategories = async ({
  shopId,
}: {
  shopId: string;
}): Promise<ProductCategoriesReadManyDTO['Response']> => {
  const res = await request<ProductCategoriesReadManyDTO['Response']>({
    method: 'GET',
    url: requestUrls.productCategories.base(shopId),
  });

  return res;
};

export const getProduct = async ({
  shopId,
  productId,
}: {
  shopId: string;
  productId: string;
}): Promise<ProductsReadOneDTO['Response']> => {
  const res = await request<ProductsReadOneDTO['Response']>({
    method: 'GET',
    url: requestUrls.products.one({ shopId, productId }),
  });

  return res;
};
