import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { i18nMessagesImporter, LocaleIds } from './intl';
import { LocaleCode } from '@chillmenu/common/dist/src/internationalization';
import { generateUseContext } from '@chillmenu/common/dist/lib/generateUseContext';

const defaultLocale: LocaleCode = 'el-GR';

type IntlContextI = {
  locale: LocaleCode;
  setLocale: React.Dispatch<React.SetStateAction<LocaleCode>>;
};

export const IntlContext = createContext<IntlContextI | undefined>(undefined);

export const useIntlContext = generateUseContext(IntlContext);

export const IntlContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [locale, setLocale] = useState<LocaleCode>(defaultLocale);

  const [messages, setMessages] = React.useState<
    Record<LocaleIds, string> | undefined
  >(undefined);

  useEffect(() => {
    i18nMessagesImporter[locale]().then((imported) => {
      setMessages(imported.default);
    });
  }, [locale]);

  return (
    <IntlContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={messages}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
