import { LocaleCode } from './localeCodes.const';

export const localeFlagCodes: Record<LocaleCode, string> = {
  'el-GR': 'GR',
  'en-US': 'US',
  'it-IT': 'IT',
  'de-DE': 'DE',
  'es-ES': 'ES',
  'fr-FR': 'FR',
  'zh-CN': 'CN',
};
