import { useMemo } from 'react';
import { ProductDTO } from '../product.dto';
import { ProductCategoriesDTO } from './productCategories.dto';

export const useCategoriesWithProductsMap = ({
  products,
  categories,
}: {
  products: ProductDTO[];
  categories: ProductCategoriesDTO[];
}): Record<
  string,
  {
    products: ProductDTO[];
    category: ProductCategoriesDTO;
  }
> => {
  return useMemo(() => {
    if (!categories.length) {
      return {};
    }
    const returnValue: Record<
      string,
      {
        products: typeof products;
        category: typeof categories[0];
      }
    > = {};

    for (const category of categories) {
      returnValue[category.id] = {
        category,
        products: [],
      };
    }

    for (const product of products) {
      const { categoryId } = product;
      returnValue[categoryId].products.push(product);
    }

    return returnValue;
  }, [categories, products]);
};
