import React from 'react';
import { Divider as MuiDivider, alpha } from '@mui/material';
import { makeStyles } from '../../chillmenu-components/Theme';

const useStyles = makeStyles()((theme) => ({
  Heavy: {
    backgroundColor: alpha(
      theme.palette.getContrastText(theme.palette.background.default),
      0.2,
    ),
  },
}));

export const Divider = (props: {
  heavy?: boolean;
  style?: React.CSSProperties;
}): JSX.Element => {
  const classNames: string[] = [];
  const { classes } = useStyles();

  if (props.heavy) {
    classNames.push(classes.Heavy);
  }

  return <MuiDivider className={classNames.join(' ')} style={props.style} />;
};
