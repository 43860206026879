import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'DialogTitle';

const classes = {
  root: `${PREFIX}-root`,
  closeButton: `${PREFIX}-closeButton`,
};

const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: 0,
    padding: theme.spacing(2),
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export interface DialogTitleProps
  extends React.ComponentProps<typeof StyledMuiDialogTitle> {
  children: React.ReactNode;
  /** if set, a Close button will be added */
  onClose?: () => void;
}

export const DialogTitle = (props: DialogTitleProps): JSX.Element => {
  const { children, onClose, ...other } = props;
  return (
    <StyledMuiDialogTitle variant="h6" className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
};
