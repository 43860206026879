import { LocaleCode } from '@chillmenu/common/dist/src/internationalization';

const localeIds = [
  'Days.Monday',
  'Days.Tuesday',
  'Days.Wednesday',
  'Days.Thursday',
  'Days.Friday',
  'Days.Saturday',
  'Days.Sunday',

  'ShopViewForm.EnterIdentifierInstructions',
  'ShopViewForm.ViewMenuC2A',
  'ShopViewForm.WrongIdentifier',

  'ShopViewerDrawer.Categories',

  'ProductPrice.ProductNotAvailable',

  'OrderViewerComponents.OrderProductQuantityModifier.Quantity',

  'OrderMenu.GoToCartC2A',
  'OrderMenu.CategoryIsNotAvailable',

  'ShopInfo.MinimumOrderPrice',
  'ShopInfo.ScheduleClosed',
  'ShopInfo.ShopIsOpen',
  'ShopInfo.ShopIsClosed',
  'ShopInfo.MoreInfo',

  'OrderProductDialog.Note',
  'OrderProductDialog.AddToOrderC2A',
  'OrderProductDialog.EditSelectionC2A',
  'OrderProductDialog.CancelAddingToOrderC2A',
  'OrderProductDialog.Price',

  'OrderCheckoutDrawer.Cart',
  'OrderCheckoutDrawer.GoBack',
  'OrderCheckoutDrawer.Logout',

  'OrderCheckoutProductsSummary.Products',
  'OrderCheckoutProductsSummary.Notes',
  'OrderCheckoutProductsSummary.BeforeDiscount',
  'OrderCheckoutProductsSummary.Discount',
  'OrderCheckoutProductsSummary.Total',
  'OrderCheckoutProductsSummary.MinimumOrderPrice',

  'OrderCheckoutUserSelection.UserEnteredInvalidOrExpiredCode',
  'OrderCheckoutUserSelection.CodeCannotBeSent',
  'OrderCheckoutUserSelection.InputEmailLabel',
  'OrderCheckoutUserSelection.InputVerificationCodeLabel',
  'OrderCheckoutUserSelection.CheckSpamHelperLabel',
  'OrderCheckoutUserSelection.SubmitCodeC2A',
  'OrderCheckoutUserSelection.RequestCodeC2A',
  'OrderCheckoutUserSelection.ChangeEmailC2A',
  'OrderCheckoutUserSelection.SendNewPasswordC2A',
  'OrderCheckoutUserSelection.UserHeader',
  'OrderCheckoutUserSelection.AcceptTermsAndPrivacyPolicy',

  'AddressSelector.InputAddress',
  'AddressSelector.NoResults',

  'UserAddressPicker.ContinueC2A',

  'DeliverySummary.CashOnDelivery',
  'DeliverySummary.CardOnDelivery',
  'DeliverySummary.CardOnline',
  'DeliverySummary.SelectedAddress',
  'DeliverySummary.CanDeliverToUserAddress',
  'DeliverySummary.CannotDeliverToUserAddress',
  'DeliverySummary.Floor',
  'DeliverySummary.InvalidFloorHelperText',
  'DeliverySummary.BellName',
  'DeliverySummary.InvalidBellNameHelperText',
  'DeliverySummary.ContactPhoneNumber',
  'DeliverySummary.InvalidContactPhoneNumberHelperText',
  'DeliverySummary.PaymentMethod',
  'DeliverySummary.OrderNote',
  'DeliverySummary.UseNewCard',
  'DeliverySummary.SaveCard',
  'DeliverySummary.UseCardWithLastDigits',
  'DeliverySummary.ErrorOnCardDeletion',

  'UserAddressEditor.City',
  'UserAddressEditor.Street',
  'UserAddressEditor.StreetNumber',
  'UserAddressEditor.PostCode',
  'UserAddressEditor.SubmitC2A',
  'UserAddressEditor.GoBackC2A',

  'OrderSubmitButton.SubmitOrderC2A',
  'OrderSubmitButton.UnableToCreateOrder',
  'OrderSubmitButton.UnableToCreateOrderContactPhone',

  'OrderCurrentStatus.ErrorFetchingOrderStatus',
  'OrderCurrentStatus.OrderConfirmationPending',
  'OrderCurrentStatus.OrderInProgress',
  'OrderCurrentStatus.OrderContactPending',
  'OrderCurrentStatus.OrderCancelled',
  'OrderCurrentStatus.OrderCompleted_BeforeEmoji',
  'OrderCurrentStatus.OrderCompleted_AfterEmoji',
  'OrderCurrentStatus.OrderNumber',
  'OrderCurrentStatus.ShopPhoneNumber',

  'Modules.OnlineOrdersNotEnabled',
  'Modules.ViewMenuC2A',
] as const;

export type LocaleIds = typeof localeIds[number];

// https://formatjs.io/docs/react-intl/#typing-message-ids-and-locale
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace FormatjsIntl {
    interface Message {
      ids: LocaleIds;
    }
    interface IntlConfig {
      locale: LocaleCode;
    }
  }
}

export const i18nMessagesImporter: Record<
  LocaleCode,
  () => Promise<{ default: Record<LocaleIds, string> }>
> = {
  'el-GR': () => import('./translations/greekTranslations'),
  'en-US': () => import('./translations/englishTranslations'),
  'it-IT': () => import('./translations/italianTranslations'),
  'de-DE': () => import('./translations/germanTranslations'),
  'es-ES': () => import('./translations/spanishTranslations'),
  'fr-FR': () => import('./translations/frenchTranslations'),
  'zh-CN': () => import('./translations/chineseTranslations'),
};

const lsKey = 'preferredLocale';
export const getUserPreferredLocale = () =>
  localStorage.getItem(lsKey) as LocaleCode | null;
export const setUserPreferredLocale = (locale: LocaleCode) =>
  localStorage.setItem(lsKey, locale);
