import { MenusDTO } from '@chillmenu/common/dist/features/menus/menus.dto';
import { ProductCategoriesDTO } from '@chillmenu/common/dist/features/products/categories/productCategories.dto';
import { ShopsDTO } from '@chillmenu/common/dist/features/shops/shops.dto';
import { generateUseContext } from '@chillmenu/common/dist/lib/generateUseContext';
import { ProductDTO } from '@chillmenu/common/features/products/product.dto';
import React, { createContext } from 'react';

export type ShopViewerContextI = {
  shop: ShopsDTO | null;
  setShop: React.Dispatch<React.SetStateAction<ShopViewerContextI['shop']>>;
  menu: MenusDTO | null;
  setMenu: React.Dispatch<React.SetStateAction<ShopViewerContextI['menu']>>;
  categories: ProductCategoriesDTO[];
  setCategories: React.Dispatch<
    React.SetStateAction<ShopViewerContextI['categories']>
  >;
  products: ProductDTO[];
  setProducts: React.Dispatch<
    React.SetStateAction<ShopViewerContextI['products']>
  >;
};

export const ShopViewerContext = createContext<ShopViewerContextI | undefined>(
  undefined,
);

export const useShopViewerContext = generateUseContext(ShopViewerContext);

export const ShopViewerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [shop, setShop] = React.useState<ShopViewerContextI['shop']>(null);
  const [menu, setMenu] = React.useState<ShopViewerContextI['menu']>(null);

  const [categories, setCategories] = React.useState<
    ShopViewerContextI['categories']
  >([]);
  const [products, setProducts] = React.useState<
    ShopViewerContextI['products']
  >([]);

  return (
    <ShopViewerContext.Provider
      value={{
        shop,
        setShop,
        menu,
        setMenu,
        categories,
        setCategories,
        products,
        setProducts,
      }}
    >
      {children}
    </ShopViewerContext.Provider>
  );
};
