import React, { useContext } from 'react';

export const generateUseContext = <T>(
  Context: React.Context<T | undefined>,
) => (): T => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('No provider for the context was found');
  }

  return context;
};
