export const getProcessedCategories = <
  T extends {
    id: string;
    isAvailable: boolean;
  },
  P
>({
  categories,
  categoriesUiOrder,
  filterEmptyCategories,
  sortByAvailability,
}: {
  categories: T[];
  categoriesUiOrder: string[];
  filterEmptyCategories?:
    | {
        enabled: false;
      }
    | {
        enabled: true;
        products: P[];
        productGetCategoryId: (product: P) => string;
      };
  sortByAvailability?: boolean;
}): T[] => {
  let processedCategories = [...categories].sort((c1, c2) => {
    if (sortByAvailability) {
      if (c1.isAvailable && !c2.isAvailable) {
        return -1;
      }
      if (!c1.isAvailable && c2.isAvailable) {
        return 1;
      }
    }
    return categoriesUiOrder.indexOf(c1.id) > categoriesUiOrder.indexOf(c2.id)
      ? 1
      : -1;
  });

  if (filterEmptyCategories?.enabled) {
    processedCategories = processedCategories.filter((c) =>
      filterEmptyCategories.products.some(
        (p) => filterEmptyCategories.productGetCategoryId(p) === c.id,
      ),
    );
  }

  return processedCategories;
};
