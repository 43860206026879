import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Button } from '@mui/material';
import { Anchor } from '@chillmenu/common/dist/components/Links/Anchor';

const PREFIX = 'DashboardLinker';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  c2a: `${PREFIX}-c2a`,
};

const Root = styled('div')({
  [`&.${classes.container}`]: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  [`& .${classes.paper}`]: {
    padding: '15px',
    marginTop: '1rem',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.c2a}`]: {
    textTransform: 'none',
    width: '100%',
  },
});

export const DashboardLinker = (): JSX.Element => {
  return (
    <Root className={classes.container}>
      <Paper className={classes.paper}>
        Διαθέτεις εστιατόριο, καφέ ή μπαρ και ενδιαφέρεσαι να ενισχύσεις την
        εμπειρία των πελατών σου; Ψηφιοποίησε σήμερα τον έντυπο κατάλογό της
        επιχείρησης σου.
        <br />
        <Anchor href="https://chillmenu.gr/" newTab noTextDecoration>
          <Button className={classes.c2a}>Μάθε περισσότερα εδώ</Button>
        </Anchor>
      </Paper>
    </Root>
  );
};
