import { Theme } from '@mui/material';

export const noUserSelection = (theme: Theme): React.CSSProperties => {
  return {
    [theme.breakpoints.down('lg')]: {
      /* disable user selection for various browsers */
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    },
  };
};
