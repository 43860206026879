import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export type ScrollTypes =
  | { type: 'hash'; value: string }
  | { type: 'category'; categoryId: string }
  | { type: 'product'; productId: string };

export class Scroller {
  public static Component: React.FC<ScrollTypes> = (props) => {
    return <span id={Scroller.getHtmlId(props)} />;
  };

  public static getHtmlId = (
    scrollType: ScrollTypes,
    prefixHashtag?: boolean,
  ): string => {
    const withPrefix = (value: string) => {
      return `${prefixHashtag && !value.startsWith('#') ? '#' : ''}${value}`;
    };

    if (scrollType.type === 'hash') {
      return withPrefix(scrollType.value);
    } else if (scrollType.type === 'category') {
      return withPrefix('category_' + scrollType.categoryId);
    } else if (scrollType.type === 'product') {
      return withPrefix('product_' + scrollType.productId);
    } else {
      throw new Error(`invalid scrollType ${scrollType}`);
    }
  };

  public static scrollTo = (scrollType: ScrollTypes): void => {
    const element = document.querySelector(
      Scroller.getHtmlId(scrollType, true),
    );

    if (!element) {
      return;
    }

    /** scroll a bit after the element top */
    const yOffset = -90;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  private hasScrolled = false;

  public scrollOnceHistoryHash = (
    {
      location,
      navigate,
    }: {
      location: ReturnType<typeof useLocation>;
      navigate: ReturnType<typeof useNavigate>;
    },
    removeHashAndReplaceHistory?: boolean,
  ): void => {
    if (this.hasScrolled) {
      return;
    }
    const { hash, pathname } = location;
    if (!hash) {
      return;
    }
    Scroller.scrollTo({ type: 'hash', value: hash });

    if (removeHashAndReplaceHistory) {
      navigate(pathname, { replace: true });
    }
  };
}
