import {
  Box,
  CardActionArea,
  Collapse,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RoomIcon from '@mui/icons-material/Room';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { MailTo } from '@chillmenu/common/dist/components/Links/Mailto';
import { Tel } from '@chillmenu/common/dist/components/Links/Tel';
import React from 'react';
import { useShopViewerContext } from '../shopViewer.context';
import { Conditional } from '@chillmenu/common/dist/components/Utils/Conditional';
import { FormattedMessage } from 'react-intl';
import { DaySchedule } from '../DaySchedule/DaySchedule';

const PREFIX = 'ShopInfoListEntry';

const classes = {
  Container: `${PREFIX}-Container`,
  ClickableArea: `${PREFIX}-ClickableArea`,
  ExpandedInfoContainer: `${PREFIX}-ExpandedInfoContainer`,
  IconRight: `${PREFIX}-IconRight`,
  IconLeft: `${PREFIX}-IconLeft`,
  Bold: `${PREFIX}-Bold`,
  OnlineIndicator: `${PREFIX}-OnlineIndicator`,
  InfoAnnouncementTypography: `${PREFIX}-InfoAnnouncementTypography`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.Container}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.ClickableArea}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.ExpandedInfoContainer}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.IconRight}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.IconLeft}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.Bold}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.OnlineIndicator}`]: {
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.InfoAnnouncementTypography}`]: {
    whiteSpace: 'pre-line',
  },
}));

export const ShopInfoListEntry = (): JSX.Element => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('lg'));

  const [collapsed, setCollapsed] = React.useState(false);

  const { shop, menu } = useShopViewerContext();

  if (!shop || !menu) {
    return <></>;
  }

  const announcement = menu.menuViewAnnouncement;
  const info = menu.menuViewInfo;

  return (
    <StyledPaper className={classes.Container} variant="outlined">
      <CardActionArea
        className={classes.ClickableArea}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5">{shop.name}</Typography>
        </Box>
        <Conditional condition={!!announcement}>
          <Typography
            variant="h6"
            className={[classes.Bold, classes.InfoAnnouncementTypography].join(
              ' ',
            )}
          >
            {announcement}
          </Typography>
        </Conditional>
        <Conditional condition={!!info}>
          <Typography
            variant="body1"
            className={classes.InfoAnnouncementTypography}
          >
            {info}
          </Typography>
        </Conditional>
        <Box
          sx={{
            display: 'flex',
            justifyContent: isSm ? 'center' : 'flex-start',
            alignItems: 'center',
          }}
        >
          <Typography>
            <FormattedMessage id="ShopInfo.MoreInfo" />
          </Typography>
          <ExpandMoreIcon
            color="primary"
            style={{ visibility: collapsed ? 'hidden' : 'visible' }}
          />
        </Box>
        <Collapse in={collapsed}>
          <div className={classes.ExpandedInfoContainer}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon className={classes.IconLeft} />
              <Typography variant="body1">
                <Tel phoneNumber={shop.phoneNumber}>{shop.phoneNumber}</Tel>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <EmailIcon className={classes.IconLeft} />
              <Typography variant="body1">
                <MailTo email={''}>{shop.contactEmail}</MailTo>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <RoomIcon className={classes.IconLeft} />
              <Typography variant="body1">
                {[
                  `${shop.address.street}, ${shop.address.number}`,
                  shop.address.city,
                  shop.address.postCode,
                ].join(', ')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <ScheduleIcon className={classes.IconLeft} />
              <div>
                <DaySchedule
                  day="Monday"
                  start={shop.schedule.mondayStart}
                  end={shop.schedule.mondayEnd}
                />
                <DaySchedule
                  day="Tuesday"
                  start={shop.schedule.tuesdayStart}
                  end={shop.schedule.tuesdayEnd}
                />
                <DaySchedule
                  day="Wednesday"
                  start={shop.schedule.wednesdayStart}
                  end={shop.schedule.wednesdayEnd}
                />
                <DaySchedule
                  day="Thursday"
                  start={shop.schedule.thursdayStart}
                  end={shop.schedule.thursdayEnd}
                />
                <DaySchedule
                  day="Friday"
                  start={shop.schedule.fridayStart}
                  end={shop.schedule.fridayEnd}
                />
                <DaySchedule
                  day="Saturday"
                  start={shop.schedule.saturdayStart}
                  end={shop.schedule.saturdayEnd}
                />
                <DaySchedule
                  day="Sunday"
                  start={shop.schedule.sundayStart}
                  end={shop.schedule.sundayEnd}
                />
              </div>
            </Box>
          </div>
        </Collapse>
      </CardActionArea>
    </StyledPaper>
  );
};
