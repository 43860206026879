import React from 'react';

import { Dialog, useTheme, useMediaQuery } from '@mui/material';

export const ResponsiveDialog = (
  props: React.ComponentProps<typeof Dialog>,
): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Dialog {...props} fullWidth maxWidth="md" fullScreen={fullScreen}>
      {props.children}
    </Dialog>
  );
};
