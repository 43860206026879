import { useMemo } from 'react';
import { ProductDTO } from './product.dto';

export const useSortedProducts = ({
  products,
  sortByAvailability,
}: {
  products: ProductDTO[];
  sortByAvailability: boolean;
}): ProductDTO[] => {
  return useMemo(
    () =>
      products.sort((a, b) => {
        if (sortByAvailability) {
          if (!a.isAvailable) {
            return 1;
          }
          if (!b.isAvailable) {
            return -1;
          }
        }
        if (typeof a.orderIndex !== 'number') {
          return 1;
        }
        if (typeof b.orderIndex !== 'number') {
          return -1;
        }
        return a.orderIndex > b.orderIndex ? 1 : -1;
      }),
    [products, sortByAvailability],
  );
};
