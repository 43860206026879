import React from 'react';
import { styled } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { Home } from '../features/home/Home.page';
import { ShopViewer } from '../features/shops/ShopViewer.page';
import { noUserSelection } from '@chillmenu/common/dist/components/styling/noUserSelection';
import { ChillMenuThemeProvider } from '@chillmenu/common/dist/chillmenu-components/Theme';
import { IntlContextProvider } from '../intl/intl.context';

const PREFIX = 'App';

const classes = {
  AppContainer: `${PREFIX}-AppContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.AppContainer}`]: {
    ...noUserSelection(theme),
  },
}));

const App = (): JSX.Element => {
  return (
    <Root className={classes.AppContainer}>
      <ChillMenuThemeProvider>
        <IntlContextProvider>
          <Router>
            <CssBaseline />
            <Routes>
              <Route path="/:shop" element={<ShopViewer />} />
              <Route path="/" element={<Home />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </IntlContextProvider>
      </ChillMenuThemeProvider>
    </Root>
  );
};

export default App;
