import { useCallback } from 'react';
import { LocaleObject } from '@chillmenu/common/src/internationalization/localeObject.type';
import { localeObjectToString } from '@chillmenu/common/dist/src/internationalization/localeObjectToString';
import { useIntl } from 'react-intl';

export const useLocaleObjectToString = () => {
  const { locale } = useIntl();

  const callback = useCallback(
    (localeObject: LocaleObject) => localeObjectToString(localeObject, locale),
    [locale],
  );

  return callback;
};
