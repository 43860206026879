import { ResponsiveDialog } from '@chillmenu/common/dist/components/Dialog/ResponsiveDialog';
import { styled } from '@mui/material/styles';
import { Conditional } from '@chillmenu/common/dist/components/Utils/Conditional';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { ViewProductCardData } from '../ProductCard/ViewProductCard';
import { Image } from '@chillmenu/common/dist/chillmenu-components/Images';
import { DialogTitle } from '@chillmenu/common/dist/components/Dialog/DialogTitle';
import { ProductPrice } from '../ProductPrice/ProductPrice';
import { useLocaleObjectToString } from '../../../intl/useLocaleObjectToString.hook';

const PREFIX = 'ViewProductDialog';

const classes = {
  BottomAction: `${PREFIX}-BottomAction`,
};

const StyledResponsiveDialog = styled(ResponsiveDialog)({
  [`& .${classes.BottomAction}`]: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
});

export const ViewProductDialog = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  product: ViewProductCardData;
}): JSX.Element => {
  const { setOpen, open, product } = props;
  const onClose = React.useCallback(() => setOpen(false), [setOpen]);

  const intl = useLocaleObjectToString();

  return (
    <StyledResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <span style={{ wordBreak: 'break-word' }}>{intl(product.name)}</span>
      </DialogTitle>
      <DialogContent dividers>
        {product.imageUrl && (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Image
              url={product.imageUrl}
              alt={intl(product.name)}
              variant="StoreFrontProductDetails"
              beforeLoadVariant="StoreFrontProductList"
              style={{
                height: '300px',
              }}
            />
          </Box>
        )}
        <Conditional condition={!!product.description}>
          <Grid item xs={12}>
            <Typography variant="body1">{intl(product.description)}</Typography>
          </Grid>
        </Conditional>
        <ProductPrice product={product} showUnavailability={true} />
      </DialogContent>
      <DialogActions>
        <div className={classes.BottomAction}>
          <Button onClick={onClose} color="secondary">
            {/* TODO: intl */}
            ΚΛΕΙΣΙΜΟ
          </Button>
        </div>
      </DialogActions>
    </StyledResponsiveDialog>
  );
};
