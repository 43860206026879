import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ProductDTO } from '../../features/products/product.dto';
import {
  LocaleCode,
  useLocaleObjectToString,
} from '../../src/internationalization';

const PREFIX = 'ProductName';

const classes = {
  ProductNameTypography: `${PREFIX}-ProductNameTypography`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.ProductNameTypography}`]: {
    color: theme.palette.text.primary,
  },
}));

export const ProductName = (props: {
  product: Pick<ProductDTO, 'name' | 'orderIndex'>;
  showProductIndexes: boolean;
  locale: LocaleCode;
}): JSX.Element => {
  const { product, locale } = props;

  const intl = useLocaleObjectToString(locale);

  let productIndex = '';
  if (typeof product.orderIndex === 'number' && props.showProductIndexes) {
    productIndex = `${product.orderIndex}. `;
  }

  return (
    <StyledTypography className={classes.ProductNameTypography} variant="h6">
      {productIndex + intl(product.name)}
    </StyledTypography>
  );
};
