import { getProcessedCategories } from '@chillmenu/common/dist/features/products/categories/util/getProcessedCategories';
import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Navigate } from '../../utilities/navigate';
import { MenuViewer } from '../menu/MenuViewer.page';
import {
  getMenu,
  getProductCategories,
  getProducts,
  getShop,
} from './shop.requests';
import { ShopViewerProvider, useShopViewerContext } from './shopViewer.context';
import { ShopViewerDrawerProvider } from './ShopViewerDrawer/shopViewerDrawer.context';
import { useIntlContext } from '../../intl/intl.context';
import { getUserPreferredLocale } from '../../intl/intl';

const _ShopViewer = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ shop: string }>();

  const {
    menu,
    setCategories,
    setMenu,
    setProducts,
    setShop,
    shop,
  } = useShopViewerContext();

  const { locale, setLocale } = useIntlContext();

  const shopIdentifierInput = params.shop || '';

  const [isDataReady, setIsDataReady] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const shopDataRes = await getShop({
          shop: shopIdentifierInput,
          locale,
        });

        setShop(shopDataRes.shop);

        const menuRes = await getMenu({
          shopId: shopDataRes.shop.id,
        });

        setMenu(menuRes.menu);

        const { preferredLocales } = shopDataRes.shop;
        const preferredLocale = getUserPreferredLocale();

        let localeToUse = preferredLocales[0] ?? 'en-US';

        if (preferredLocale && preferredLocales.includes(preferredLocale)) {
          localeToUse = preferredLocale;
        }

        setLocale(localeToUse);

        document.title = `${shopDataRes.shop.name} Menu | ChillMenu`;
      } catch (err) {
        new Navigate(navigate, location, 'replace').wrongSearchIdentifier();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopIdentifierInput, navigate, location]);

  React.useEffect(() => {
    if (isDataReady || !shop || !menu) {
      return;
    }
    (async () => {
      try {
        const [productsRes, productCategoriesRes] = await Promise.all([
          getProducts({
            shopId: shop.id,
          }),
          getProductCategories({
            shopId: shop.id,
          }),
        ]);

        const sortedCategories = getProcessedCategories({
          categories: productCategoriesRes.productCategories,
          categoriesUiOrder: menu.categoriesUiOrder,
          sortByAvailability: true,
          filterEmptyCategories: {
            enabled: true,
            products: productsRes.products,
            productGetCategoryId: (p) => p.category.id,
          },
        });

        setCategories(sortedCategories);
        setProducts(
          productsRes.products.map((product) => ({
            ...product,
            categoryId: product.category.id,
          })),
        );

        new Navigate(navigate, location, 'replace').shopMenuViewer(
          shop.shortName,
        );

        setIsDataReady(true);
      } catch (err) {
        new Navigate(navigate, location, 'replace').wrongSearchIdentifier();
      }
    })();
  }, [shop, menu, navigate, isDataReady, setCategories, setProducts, location]);

  if (!isDataReady) {
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  const component = <MenuViewer />;

  return <ShopViewerDrawerProvider>{component}</ShopViewerDrawerProvider>;
};

export const ShopViewer = () => (
  <ShopViewerProvider>
    <_ShopViewer />
  </ShopViewerProvider>
);
