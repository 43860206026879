import React from 'react';
import { Divider } from '@chillmenu/common/dist/components/Divider/Divider';
import { FormattedMessage } from 'react-intl';
import { ListItem, ListItemText, List } from '@mui/material';
import { Scroller } from '@chillmenu/common/dist/chillmenu-components/Scroller';
import ResponsiveDrawer from '@chillmenu/common/dist/components/Drawer/ResponsiveDrawer';
import { useLocaleObjectToString } from '../../../intl/useLocaleObjectToString.hook';
import { useShopViewerDrawerContext } from './shopViewerDrawer.context';

export const ShopViewerDrawer: React.FC<{
  children: React.ReactNode;
  title: string;
  label: string;
}> = (props) => {
  let drawerMobileClose: VoidFunction;

  const intl = useLocaleObjectToString();

  const { categories, title } = useShopViewerDrawerContext();

  const categoriesDrawerLists = categories.length ? (
    <>
      <Divider heavy />
      <ListItem>
        <ListItemText
          primary={
            <b>
              <FormattedMessage id="ShopViewerDrawer.Categories" />
            </b>
          }
        />
      </ListItem>
      <Divider heavy />
      <List>
        {categories.map((category) => (
          <div
            key={category.id}
            onClick={() => {
              Scroller.scrollTo({
                type: 'category',
                categoryId: category.id,
              });
              drawerMobileClose();
            }}
          >
            <ListItem button>
              <ListItemText primary={intl(category.name)} />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </>
  ) : null;

  const drawerLists = <>{categoriesDrawerLists}</>;

  return (
    <ResponsiveDrawer
      drawerLists={drawerLists}
      title={title || props.title}
      label={props.label}
      toolbarTogglesDrawer={true}
      toolbarIconStyle={{
        margin: '0',
        marginRight: '0.2rem',
        padding: '0',
      }}
      getCloseMobile={(gcm) => (drawerMobileClose = gcm)}
    >
      {props.children}
    </ResponsiveDrawer>
  );
};
