export abstract class RequestUrls {
  constructor(private serverBaseUrl: string) {}

  protected withServerUrl = (suffix: string): string =>
    new URL(suffix, this.serverBaseUrl).toString();

  protected pathFunction = (
    path: string,
  ): {
    /** just the path */
    path: () => string;
    /** the server base url with the path */
    base: () => string;
    /** the base url slash the id */
    one: (id: string) => string;
  } => {
    const base = this.withServerUrl(path);

    return {
      path: () => path,
      base: () => base,
      one: (id: string) => base + id,
    };
  };
}

export class AdminRequestUrls extends RequestUrls {
  public login = this.pathFunction('/admin/login/');
  public tokenValidate = this.pathFunction('/admin/tokenValidate/');
  public shops = this.pathFunction('/admin/shops/');
}

export const memberParams = {
  orderIterations: {
    orderId: 'orderId',
  },
} as const;
export class MemberRequestUrls extends RequestUrls {
  public login = this.pathFunction('/dashboard/login/');
  public shops = this.pathFunction('/dashboard/shops/');
  public tokenValidate = this.pathFunction('/dashboard/tokenValidate/');
  public productCategories = this.pathFunction(
    '/dashboard/products/categories/',
  );
  public menus = this.pathFunction('/dashboard/menus/');
  public products = this.pathFunction('/dashboard/products/');
  public productOptionValues = this.pathFunction(
    '/dashboard/products/options/values/',
  );
}

export const storeFrontParams = {
  menus: {
    shopId: 'shopId',
  },
  products: {
    shopId: 'shopId',
  },
  productCategories: {
    shopId: 'shopId',
  },
  customerDataOfShop: {
    shopId: 'shopId',
  },
} as const;

export class StoreFrontRequestUrls extends RequestUrls {
  public loginCodes = this.pathFunction('/loginCodes/');
  public tokenValidate = this.pathFunction('/tokenValidate/');
  public shops = this.pathFunction('/shops/');
  public customerUsers = this.pathFunction('/users/');
  public menus = {
    path: (): string => `/shops/:${storeFrontParams.menus.shopId}/menus/`,
    base: (shopId: string): string =>
      this.withServerUrl(`/shops/${shopId}/menus/`),
    one: ({ shopId }: { shopId: string }): string => this.menus.base(shopId),
    params: storeFrontParams.menus.shopId,
  };
  public products = {
    path: (): string => `/shops/:${storeFrontParams.products.shopId}/products/`,
    base: (shopId: string): string =>
      this.withServerUrl(`/shops/${shopId}/products/`),
    one: ({
      shopId,
      productId,
    }: {
      shopId: string;
      productId: string;
    }): string => this.products.base(shopId) + productId,
    params: storeFrontParams.products.shopId,
  };
  public productCategories = {
    path: (): string =>
      `/shops/:${storeFrontParams.productCategories.shopId}/productCategories/`,
    base: (shopId: string): string =>
      this.withServerUrl(`/shops/${shopId}/productCategories/`),
    one: ({
      shopId,
      productCategoryId,
    }: {
      shopId: string;
      productCategoryId: string;
    }): string => this.productCategories.base(shopId) + productCategoryId,
    params: storeFrontParams.productCategories.shopId,
  };
  public customerDataOfShop = {
    path: (): string =>
      `/shops/:${storeFrontParams.customerDataOfShop.shopId}/customerData/`,
    base: (shopId: string): string =>
      this.withServerUrl(`/shops/${shopId}/customerData/`),
  };
}
