import React from 'react';

export const Anchor: React.FunctionComponent<{
  href: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  newTab?: boolean;
  noTextDecoration?: boolean;
}> = (props) => {
  const { href, children, newTab, noTextDecoration, className } = props;
  const anchorProps: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & { style: React.CSSProperties } = {
    href,
    style: props.style || {},
  };
  if (newTab) {
    anchorProps.target = '_blank';
    anchorProps.rel = 'noopener noreferrer';
  }
  if (noTextDecoration) {
    anchorProps.style.textDecoration = 'none';
    anchorProps.style.color = 'unset';
  }

  return (
    <a {...anchorProps} className={className}>
      {children}
    </a>
  );
};
