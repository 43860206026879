import { Image } from '@chillmenu/common/dist/chillmenu-components/Images';
import {
  ProductDescription,
  ProductName,
} from '@chillmenu/common/dist/chillmenu-components/ProductCard';
import { Scroller } from '@chillmenu/common/dist/chillmenu-components/Scroller';
import { makeStyles } from '@chillmenu/common/dist/chillmenu-components/Theme';
import { Conditional } from '@chillmenu/common/dist/components/Utils/Conditional';
import { alpha, CardActionArea, Grid, Paper } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocaleObjectToString } from '../../../intl/useLocaleObjectToString.hook';
import { ViewProductDialog } from '../ViewProductDialog/ViewProductDialog';
import { ProductPrice } from '../ProductPrice/ProductPrice';
import { ProductDTO } from '@chillmenu/common/dist/features/products/product.dto';

const useStyles = makeStyles()((theme) => ({
  Container: {
    marginTop: '0.5rem',
  },
  ActionArea: {
    padding: '1rem',
    wordBreak: 'break-word',
  },
  NotAvailable: {
    backgroundColor: alpha(theme.palette.background.paper, 0.3),
  },
  InformationContainer: {
    textAlign: 'left',
  },
}));

export type ViewProductCardData = Pick<
  ProductDTO,
  | 'id'
  | 'name'
  | 'description'
  | 'orderIndex'
  | 'pricing'
  | 'isAvailable'
  | 'imageUrl'
>;

export const ViewProductCard = (props: {
  showProductIndexes: boolean;
  product: ViewProductCardData;
}): JSX.Element => {
  const [productDialogOpen, setProductDialogOpen] = React.useState(false);
  const { classes } = useStyles();
  const { locale } = useIntl();
  const intl = useLocaleObjectToString();

  const { product, showProductIndexes } = props;
  const { imageUrl } = product;

  const actionAreaClasses = [classes.ActionArea];
  if (!product.isAvailable) {
    actionAreaClasses.push(classes.NotAvailable);
  }

  const containerClasses = [classes.Container];
  if (!product.isAvailable) {
    containerClasses.push(classes.NotAvailable);
  }

  const textComponent = (
    <div>
      <div className={classes.InformationContainer}>
        <ProductName
          product={product}
          showProductIndexes={showProductIndexes}
          locale={locale}
        />
        <ProductDescription product={product} locale={locale} />
      </div>
      <ProductPrice product={product} showUnavailability={true} />
    </div>
  );

  return (
    <>
      <Paper
        className={containerClasses.join(' ')}
        id={Scroller.getHtmlId({ type: 'product', productId: product.id })}
        variant="outlined"
      >
        <CardActionArea
          className={actionAreaClasses.join(' ')}
          key={product.id}
          onClick={() => setProductDialogOpen(true)}
        >
          <Conditional condition={!imageUrl}>{textComponent}</Conditional>
          <Conditional condition={!!imageUrl}>
            <Grid container spacing={1}>
              <Grid item lg={9} md={8} xs={7}>
                {textComponent}
              </Grid>
              {imageUrl && (
                <Grid item lg={3} md={4} xs={5}>
                  <Image
                    url={imageUrl}
                    alt={intl(product.name)}
                    variant="StoreFrontProductList"
                    style={{
                      width: '190px',
                      height: '107px',
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Conditional>
        </CardActionArea>
      </Paper>
      <ViewProductDialog
        open={productDialogOpen}
        product={product}
        setOpen={setProductDialogOpen}
      />
    </>
  );
};
