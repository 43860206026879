import { LocaleCode } from './localeCodes.const';

export const localeTranslationsInGreek: Record<LocaleCode, string> = {
  'el-GR': 'Ελληνικά',
  'en-US': 'Αγγλικά',
  'it-IT': 'Ιταλικά',
  'de-DE': 'Γερμανικά',
  'es-ES': 'Ισπανικά',
  'fr-FR': 'Γαλλικά',
  'zh-CN': 'Κινέζικα',
};

export const localeTranslationsInOwnLocale: Record<LocaleCode, string> = {
  'el-GR': 'Ελληνικά',
  'en-US': 'English',
  'it-IT': 'Italiano',
  'de-DE': 'Deutsch',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'zh-CN': '普通话',
};
