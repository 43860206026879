import createStyleCache from '@emotion/cache';
import { CacheProvider as StyleCacheProvider } from '@emotion/react';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createMakeAndWithStyles } from 'tss-react';

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#ffaa40',
      contrastText: '#fefefe',
    },
    text: {
      primary: '#303030',
      secondary: '#303030',
    },
    background: {
      default: '#fefefe',
      paper: '#f9f9f9',
    },
    common: {
      black: '#303030',
      white: '#fefefe',
    },
    secondary: {
      main: '#ff991c',
    },
    action: {
      selectedOpacity: 0.2,
    },
    success: {
      main: '#4caf50',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: 'rgb(3 3 3 / 10%) 0px 2px 10px',
        },
      },
    },
  },
};

export const muiCache = createStyleCache({
  key: 'mui',
  prepend: true,
});

const providedTheme = createTheme(theme);

export const ChillMenuThemeProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => (
  <StyleCacheProvider value={muiCache}>
    <ThemeProvider theme={providedTheme}>{props.children}</ThemeProvider>
  </StyleCacheProvider>
);

const useTheme = () => providedTheme;

export const { makeStyles, useStyles } = createMakeAndWithStyles({ useTheme });
