import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Typography,
  TextField,
  FormControl,
  Button,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navigate } from '../../../utilities/navigate';

const PREFIX = 'ShopViewForm';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  identifierInput: `${PREFIX}-identifierInput`,
  submitButton: `${PREFIX}-submitButton`,
  wrongIdentifierPaper: `${PREFIX}-wrongIdentifierPaper`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '15px',
    marginTop: '1rem',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.identifierInput}`]: {
    fontSize: '2em',
    textAlign: 'center',
  },

  [`& .${classes.submitButton}`]: {
    marginTop: '1rem',
  },

  [`& .${classes.wrongIdentifierPaper}`]: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.common.white,
  },
}));

export const wrongIdentifierSearch = {
  name: 'wrongIdentifier',
  url: () => `/?${wrongIdentifierSearch.name}=true`,
} as const;

export const ShopViewForm = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const [identifier, setIdentifier] = React.useState('');
  const [isFormValid, setIsFormValid] = React.useState(false);

  const onSubmit = async () => {
    new Navigate(navigate, location).shopMenuViewer(identifier);
  };

  const searchParams = new URLSearchParams(location.search);
  const hasWrongIdentifier = !!searchParams.get(wrongIdentifierSearch.name);

  return (
    <Root
      className={classes.container}
      onKeyDown={(event) => {
        if (isFormValid && event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          onSubmit();
        }
      }}
    >
      <Paper className={classes.paper}>
        {hasWrongIdentifier && (
          <Paper className={classes.wrongIdentifierPaper}>
            <FormattedMessage id="ShopViewForm.WrongIdentifier" />
          </Paper>
        )}
        <Typography variant="h5">
          <FormattedMessage id="ShopViewForm.EnterIdentifierInstructions" />
        </Typography>
        <FormControl variant="standard">
          <TextField
            type="number"
            value={identifier}
            variant="outlined"
            autoFocus
            onFocus={(e) => {
              e.target.select();
            }}
            onChange={(e) => {
              const { value } = e.target;
              setIdentifier(value);

              if (value.length >= 3) {
                setIsFormValid(true);
              } else {
                setIsFormValid(false);
              }
            }}
            inputProps={{
              className: classes.identifierInput,
              min: 0,
            }}
          />
        </FormControl>
        <Button
          className={classes.submitButton}
          disabled={!isFormValid}
          variant="contained"
          color="primary"
          size="large"
          onClick={onSubmit}
        >
          <FormattedMessage id="ShopViewForm.ViewMenuC2A" />
        </Button>
      </Paper>
    </Root>
  );
};
