import React from 'react';
import { Link } from '@mui/material';

export const Tel = ({
  phoneNumber,
  children,
}: {
  phoneNumber: string;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Link
      href={`tel:${phoneNumber}`}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
        e.stopPropagation()
      }
      underline="hover"
    >
      {children}
    </Link>
  );
};
